const DEVICE = {
  // MITO100: 2,
  SMARTLIGHT: 3,
  OSPROPLUS150: 5,
  OSPROPLUS300: 6,
  ESPBM2024: 7,
}

const STATUS = {
  OFF: 0,
  CONNECTED: 1,
  TRANSMITTING: 2,
}


export {
  DEVICE,
  STATUS,
}