import React, {useEffect, useState} from 'react'
import useDevices from '../hook/useDevices'
import { sleep } from '../lib/util'
import Adjuster from './Adjuster'


export default function({
  className='device settings',
  ID,
  time,
}) {
  const {get} = useDevices()
  
  const [device, setDevice] = useState({})
  const [brightness, setBrightness] = useState([50,50,50,50,50,50])
  const [frequency, setFrequency] = useState([1000,1000,1000,1000,1000,1000])
  
  
  const flash = async()=>{
    await device.flash()
  }
  

  const getDim = index=>{
    return ()=>{
      return brightness[index]
    }
  }
  
  const setDim = index=>{
    return val=>{
      brightness[index] = +val
      setBrightness([...brightness])
    }
  }
  
  
  const getFreq = index=>{
    return ()=>{
      return frequency[index]
    }
  }
  
  const setFreq = index=>{
    return val=>{
      frequency[index] = +val
      setFrequency([...frequency])
    }
  }


  const sendSettings = async(event)=>{
    device.set(0, brightness[0], frequency[0])
    await sleep(234)
    device.set(1, brightness[1], frequency[1])
    await sleep(234)
    device.set(2, brightness[2], frequency[2])
    await sleep(234)
    device.set(3, brightness[3], frequency[3])
    await sleep(234)
    device.set(4, brightness[4], frequency[4])
    await sleep(234)
    device.set(5, brightness[5], frequency[5])
    await sleep(234)
    device.on()
  }
  
  
  useEffect(() => {
    setDevice(get(ID))
  }, [ID])
  
  
  useEffect(() => {
    if (device?.setTime && time) {
      Promise.all([
        device.setTime(time),
        sleep(234),
      ])
    }
  }, [device, time])


  return (<>
    <div className={className}>
      <div>ESPBM2024 {device?.name}</div>
      <br />
      
      <Adjuster
        getBrightness={getDim(0)}
        setBrightness={setDim(0)}
        getFrequency={getFreq(0)}
        setFrequency={setFreq(0)}
        wavelength="610"
      />
      
      <Adjuster
        getBrightness={getDim(1)}
        setBrightness={setDim(1)}
        getFrequency={getFreq(1)}
        setFrequency={setFreq(1)}
        wavelength="630"
      />
      
      <Adjuster
        getBrightness={getDim(2)}
        setBrightness={setDim(2)}
        getFrequency={getFreq(2)}
        setFrequency={setFreq(2)}
        wavelength="660"
      />
      
      <Adjuster
        getBrightness={getDim(3)}
        setBrightness={setDim(3)}
        getFrequency={getFreq(3)}
        setFrequency={setFreq(3)}
        wavelength="810"
      />
      
      <Adjuster
        getBrightness={getDim(4)}
        setBrightness={setDim(4)}
        getFrequency={getFreq(4)}
        setFrequency={setFreq(4)}
        wavelength="830"
      />
      
      <Adjuster
        getBrightness={getDim(5)}
        setBrightness={setDim(5)}
        getFrequency={getFreq(5)}
        setFrequency={setFreq(5)}
        wavelength="850"
      />
      
      {/* <pre>
        {JSON.stringify(brightness, null, 2)}
        {JSON.stringify(frequency, null, 2)}
      </pre> */}
      
      <div className="flexy">
        <button onClick={sendSettings} type="button">Set</button>
        <button onClick={flash}>Show</button>
        <button onClick={E=>device.start()}>Start</button>
        <button onClick={E=>device.stop()}>Stop</button>
      </div>
    </div>
  </>)
}