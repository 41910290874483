import React,{useEffect, useState} from 'react'


export default function Adjuster({
  initialBrightness=50,
  initialFrequency=10000,
  getBrightness,
  getFrequency,
  setBrightness,
  setFrequency,
  wavelength=777,
}) {
  const [dim, setDim] = useState(initialBrightness)
  const [freq, setFreq] = useState(initialFrequency)
  

  useEffect(() => {
    setDim(getBrightness())
    setFreq(getFrequency())
  }, [])
  

  useEffect(() => {
    setBrightness(dim)
  }, [dim])


  useEffect(() => {
    setFrequency(freq)
  }, [freq])
  
  
  
  return <div>
    {wavelength}nm&nbsp;
    <br />
    <label>
      Brightness:&nbsp;
      <input onChange={E=>setDim(+E.target.value)} type="range" max="100" value={dim} />
      {dim}%
    </label>
    <br />    
    <label>
      Frequency:&nbsp;
      <input onChange={E=>setFreq(+E.target.value)} type="range" max="20000" value={freq} />
      {freq}
    </label>
  </div>
}
