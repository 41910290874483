import fullConfiguration from '../config'
import Device from './Device'
import { createMessage } from './esProPlus'
import {sleep} from './util'

const config = fullConfiguration.ESPBM2024

const UUID = {
  read: config.READ,
  write: config.WRITE,
  service: config.SERVICE,
}

const {MSG, SYS} = config


function byteLoad(ID, brightness, frequency) {
  const dim = brightness & 0xFF
  
  const freqHigh = (frequency >> 8) & 0xFF
  const freqLow = frequency & 0xFF
  
  let id = 0x00
  
  if (ID === 610) id = 0x00
  if (ID === 630) id = 0x01
  if (ID === 660) id = 0x02
  if (ID === 810) id = 0x03
  if (ID === 830) id = 0x04
  if (ID === 850) id = 0x05
  
  const bytes = new Uint8Array([
    id,
    0x00,
    dim,
    freqHigh,
    freqLow,
  ])
  
  return bytes
}


class ESPBM2024 extends Device {
  msgGapTime = 234
  readChannel = {}
  settings = {}
  writeChannel = {}
  
  constructor(name) {
    if (name) {
      super({
        deviceName: name,
        uuid: UUID.service,
      })
    } else {
      super({
        deviceNamePrefix: 'OsteoStro',
        uuid: UUID.service,
      })
    }
  }
  
  
  async initialize() {
    this.connected = false
    this.transmitting = false
    
    await this.connect()
    
    this.connected = true
    
    
    this.readChannel = await this.characteristic(UUID.read)
    this.writeChannel = await this.characteristic(UUID.write)
    
    this.readChannel.addEventListener('characteristicvaluechanged', data=>{
      // console.log(data)
    })
    
    this.readChannel.startNotifications()
    
    this.transmitting = true
    
    console.log('OS-Pro+ intialized')
    
    this.name = this.device.name
    this.id = this.device.id
    
    return this.device.id
  }
  
  async flash() {
    const priorTime = this.settings.time
    const priorRedBrightness = this.settings.redBrightness
    const priorRedFrequency = this.settings.redFrequency
    const priorNIRBrightness = this.settings.NIRBrightness
    const priorNIRFrequency = this.settings.NIRFrequency


    this.setTime(1)
    await sleep(this.msgGapTime)
    
    this.redBrightness(10)
    await sleep(this.msgGapTime)
    
    this.NIRBrightness(0)
    await sleep(this.msgGapTime)
    
    this.redFrequency(2)
    await sleep(this.msgGapTime)
    
    this.NIRFrequency(2)
    await sleep(this.msgGapTime)
    
    this.start()
    await sleep(4567)
    this.stop()
    
    this.setTime(priorTime)
    await sleep(this.msgGapTime)
    this.redBrightness(priorRedBrightness)
    await sleep(this.msgGapTime)
    this.NIRBrightness(priorNIRBrightness)
    await sleep(this.msgGapTime)
    this.redFrequency(priorRedFrequency)
    await sleep(this.msgGapTime)
    this.NIRFrequency(priorNIRFrequency)
    await sleep(this.msgGapTime)
  }
  
  getAddress() {
    this.sendCommand(SYS.INFO, SYS.INFO_ADDRESS, -1)
  }

  getModel() {
    this.sendCommand(SYS.INFO, SYS.INFO_MODEL, -1)
  }

  getStatus() {
    this.sendCommand(SYS.STATUS, 0, -1)
  }

  getTime() {
    this.sendCommand(SYS.TIME, 0, -1)
  }

  getVersion() {
    this.sendCommand(SYS.INFO, SYS.INFO_VER, -1)
  }


  off() {
    const head = 0xFF
    let payload = 0x00
    
    // set bit 1 to 0
    payload &= 0xFE
    
    // set bit 0 to 1
    payload |= 0x01
    
    const command = new Uint8Array([
      head,
      payload,
    ])
    
    this.sendCommand(command)
  }
  
  
  on() {
    const head = 0xFF
    let payload = 0x00
    
    // set bit 1 to 1
    payload |= 0x02
    
    // set bit 0 to 0
    payload &= 0xFE
    
    const command = new Uint8Array([
      head,
      payload,
    ])
    
    this.sendCommand(command)
  }
  
  
  set(channel, brightness, frequency) {
    this.settings.brightness610 = brightness
    this.settings.frequency610 = frequency
    
    const cmd = byteLoad(channel, brightness, frequency)
    
    console.log('ESPBM2024 set', cmd)
    this.sendCommand(cmd)
  }
  
  
  sendCommand(command) {
    try {
      this.writeChannel.writeValue(command)
    } catch (error) {
      console.error(error)
    }
  }
  
  
  /**
   * 
   * @param {Integer} value ~ time in minutes
   */
  setTime(value) {
    this.settings.time = value
    this.sendCommand(SYS.CONTROL, SYS.CONTROL_TIME, +value, true)
  }
  
  
  start() {
    this.sendCommand(SYS.CONTROL, SYS.CONTROL_START, -1)
  }
  

  stop() {
    this.sendCommand(SYS.CONTROL, SYS.CONTROL_STOP, -1)
  }
}


export default ESPBM2024